.row-card-holder-details .ul-card-details li p{
    font-size: 1.6rem;
    margin-bottom: 0.3rem;
    font-weight: 600;
    color: black;

}
.row-card-holder-details .ul-card-details li h5{
    font-size: 1.4rem;
    margin-bottom: 0.3rem;
    font-weight: 500;
}