@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
html {
  font-size: 10px;
}
.note-break{
  word-break:break-all !important;
}
.icon-ChangePinhover:before {
  content: "\e995";
  color: #fff;
}

.icon-RoleManagementHover:before {
  content: "\e996";
  color: #fff;
}

.icon-ChangePIN:before {
  content: "\e993";
}

.icon-RoleManagement:before {
  content: "\e994";
}

.icon-Wallet:before {
  content: "\e97e";
  /* color: #89455e; */
}

.icon-PDF:before {
  content: "\e97d";
  color: #89455e;
}

.icon-ATM-Limit .path1:before {
  content: "\e96f";
  color: #c1a6b0;
  opacity: 0.4;
}

.icon-ATM-Limit .path2:before {
  content: "\e970";
  margin-left: -1em;
  color: #89455e;
}

.icon-Credit-Limit .path1:before {
  content: "\e971";
  color: #c1a6b0;
  opacity: 0.4;
}

.icon-Credit-Limit .path2:before {
  content: "\e972";
  margin-left: -1em;
  color: #89455e;
}

.icon-Online-Limit .path1:before {
  content: "\e973";
  color: #c1a6b0;
  opacity: 0.4;
}

.icon-Online-Limit .path2:before {
  content: "\e974";
  margin-left: -1em;
  color: #89455e;
}

.icon-Transfer-Limit .path1:before {
  content: "\e97b";
  color: #c1a6b0;
  opacity: 0.4;
}

.icon-Transfer-Limit .path2:before {
  content: "\e97c";
  margin-left: -1em;
  color: #89455e;
}

.icon-btn_arrow_left:before {
  content: "\e93f";
  color: #89455e;
}

.icon-btn_arrow_right:before {
  content: "\e940";
  color: #89455e;
}

.icon-btn_level_down:before {
  content: "\e941";
  color: #89455e;
}

.icon-btn_level_up:before {
  content: "\e942";
  color: #89455e;
}

.icon-edit-mini:before {
  content: "\e93e";
}

.icon-money-hover:before {
  content: "\e93d";
  color: #fff;
}

.icon-accepted:before {
  content: "\e91c";
}

.icon-rejected:before {
  content: "\e92b";
}

.icon-pending:before {
  content: "\e92c";
}

.icon-dashboard-hover:before {
  content: "\e913";
  color: #fff;
}

.icon-help-hover:before {
  content: "\e90e";
  color: #fff;
}

.icon-transaction-hover:before {
  content: "\e90f";
  color: #fff;
}

.icon-settings-hover:before {
  content: "\e929";
  color: #fff;
}

.icon-deposit-hover:before {
  content: "\e92a";
  color: #fff;
}

.icon-card-hover:before {
  content: "\e9ae";
  color: #fff;
}

.icon-client-hover:before {
  content: "\e987";
  color: #fff;
}

.icon-help:before {
  content: "\e986";
}

.icon-bo-arrow:before {
  content: "\e90d";
}

.icon-calender-mini:before {
  content: "\e985";
}

.icon-canada .path1:before {
  content: "\e935";
  color: white;
}

.icon-canada .path2:before {
  content: "\e936";
  margin-left: -1.412109375em;
  color: red;
}

.icon-upload-file .path1:before {
  content: "\e980";
  color: #c1a6b0;
}

.icon-upload-file .path2:before {
  content: "\e981";
  margin-left: -1.4443359375em;
  color: #89455e;
  opacity: 0.61;
}

.icon-error .path1:before {
  content: "\e933";
  color: #e6dbdf;
  opacity: 0.61;
}

.icon-error .path2:before {
  content: "\e934";
  margin-left: -1em;
  color: #89455e;
}

.icon-successful .path1:before {
  content: "\e945";
  color: #e6dbdf;
  opacity: 0.61;
}

.icon-successful .path2:before {
  content: "\e97f";
  margin-left: -1em;
  color: #89455e;
}

.icon-hidden:before {
  content: "\e943";
}

.icon-show:before {
  content: "\e944";
}

.icon-add .path1:before {
  content: "\e900";
  color: #e6dbdf;
}

.icon-add .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: #89455e;
}

.icon-add .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: #89455e;
}

.icon-add-client .path1:before {
  content: "\e903";
  color: #c1a6b0;
}

.icon-add-client .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-add-client .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: #89455e;
}

.icon-add-client .path4:before {
  content: "\e906";
  margin-left: -1em;
  color: #e6dbdf;
}

.icon-add-client .path5:before {
  content: "\e907";
  margin-left: -1em;
  color: white;
}

.icon-add-client .path6:before {
  content: "\e908";
  margin-left: -1em;
  color: white;
}

.icon-address:before {
  content: "\e909";
  color: #c1a6b0;
}

.icon-calender:before {
  content: "\e90a";
  color: #c1a6b0;
}

.icon-card:before {
  content: "\e90b";
}

.icon-card-check:before {
  content: "\e90c";
  color: #c1a6b0;
}

.icon-card-id:before {
  content: "\e910";
  color: #89455e;
}

.icon-cirtual-card:before {
  content: "\e911";
  color: #fff;
}

.icon-client:before {
  content: "\e912";
}

.icon-clients-empty .path1:before {
  content: "\e914";
  color: #89455e;
}

.icon-clients-empty .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: #89455e;
}

.icon-clients-empty .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: #89455e;
}

.icon-clients-empty .path4:before {
  content: "\e917";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-clients-empty .path5:before {
  content: "\e918";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-clients-empty .path6:before {
  content: "\e919";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-company:before {
  content: "\e91a";
  color: #c1a6b0;
}

.icon-dashboard:before {
  content: "\e91b";
}

.icon-deposit:before {
  content: "\e91d";
}

.icon-deposit-emptystate .path1:before {
  content: "\e91e";
  color: #c1a6b0;
}

.icon-deposit-emptystate .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-deposit-emptystate .path3:before {
  content: "\e920";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-deposit-emptystate .path4:before {
  content: "\e921";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-deposit-emptystate .path5:before {
  content: "\e922";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-deposit-emptystate .path6:before {
  content: "\e923";
  margin-left: -1em;
  color: #89455e;
}

.icon-deposit-emptystate .path7:before {
  content: "\e924";
  margin-left: -1em;
  color: #e6dbdf;
}

.icon-deposit-emptystate .path8:before {
  content: "\e925";
  margin-left: -1em;
  color: white;
}

.icon-deposit-emptystate .path9:before {
  content: "\e926";
  margin-left: -1em;
  color: white;
}

.icon-deposit-emptystate .path10:before {
  content: "\e927";
  margin-left: -1em;
  color: white;
}

.icon-deposit-emptystate .path11:before {
  content: "\e928";
  margin-left: -1em;
  color: white;
}

.icon-description:before {
  content: "\e92d";
  color: #c1a6b0;
}

.icon-edit:before {
  content: "\e92e";
  color: #c1a6b0;
}

.icon-edit-backgorund .path1:before {
  content: "\e92f";
  color: white;
}

.icon-edit-backgorund .path2:before {
  content: "\e930";
  margin-left: -1em;
  color: #161618;
}

.icon-edit-backgorund .path3:before {
  content: "\e931";
  margin-left: -1em;
  color: #161618;
}

.icon-email:before {
  content: "\e932";
  color: #c1a6b0;
}

.icon-eye .path1:before {
  content: "\e937";
  color: #f4f1f2;
}

.icon-eye .path2:before {
  content: "\e938";
  margin-left: -1em;
  color: #161618;
}

.icon-eye .path3:before {
  content: "\e939";
  margin-left: -1em;
  color: #161618;
}

.icon-favourite .path1:before {
  content: "\e93a";
  color: #e6dbdf;
}

.icon-favourite .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: #89455e;
}

.icon-file:before {
  content: "\e93c";
  color: #c1a6b0;
}

.icon-home:before {
  content: "\e946";
  color: #c1a6b0;
}

.icon-info:before {
  content: "\e947";
  color: #89455e;
}

.icon-issued-card .path1:before {
  content: "\e948";
  color: #c1a6b0;
}

.icon-issued-card .path2:before {
  content: "\e949";
  margin-left: -1em;
  color: white;
}

.icon-issued-card .path3:before {
  content: "\e94a";
  margin-left: -1em;
  color: white;
}

.icon-issued-card .path4:before {
  content: "\e94b";
  margin-left: -1em;
  color: white;
}

.icon-issued-card .path5:before {
  content: "\e94c";
  margin-left: -1em;
  color: white;
}

.icon-issued-card .path6:before {
  content: "\e94d";
  margin-left: -1em;
  color: white;
}

.icon-issued-card .path7:before {
  content: "\e94e";
  margin-left: -1em;
  color: white;
}

.icon-issued-card .path8:before {
  content: "\e94f";
  margin-left: -1em;
  color: white;
}

.icon-issued-card .path9:before {
  content: "\e950";
  margin-left: -1em;
  color: #89455e;
}

.icon-issued-card .path10:before {
  content: "\e951";
  margin-left: -1em;
  color: #e6dbdf;
}

.icon-issued-card .path11:before {
  content: "\e952";
  margin-left: -1em;
  color: white;
}

.icon-load-card .path1:before {
  content: "\e953";
  color: #c1a6b0;
}

.icon-load-card .path2:before {
  content: "\e954";
  margin-left: -1em;
  color: white;
}

.icon-load-card .path3:before {
  content: "\e955";
  margin-left: -1em;
  color: white;
}

.icon-load-card .path4:before {
  content: "\e956";
  margin-left: -1em;
  color: white;
}

.icon-load-card .path5:before {
  content: "\e957";
  margin-left: -1em;
  color: white;
}

.icon-load-card .path6:before {
  content: "\e958";
  margin-left: -1em;
  color: white;
}

.icon-load-card .path7:before {
  content: "\e959";
  margin-left: -1em;
  color: white;
}

.icon-load-card .path8:before {
  content: "\e95a";
  margin-left: -1em;
  color: #89455e;
}

.icon-load-card .path9:before {
  content: "\e95b";
  margin-left: -1em;
  color: #e6dbdf;
}

.icon-load-card .path10:before {
  content: "\e95c";
  margin-left: -1em;
  color: white;
}

.icon-load-card .path11:before {
  content: "\e95d";
  margin-left: -1em;
  color: white;
}

.icon-load-card .path12:before {
  content: "\e95e";
  margin-left: -1em;
  color: white;
}

.icon-load-card .path13:before {
  content: "\e95f";
  margin-left: -1em;
  color: white;
}

.icon-load-card .path14:before {
  content: "\e960";
  margin-left: -1em;
  color: white;
}

.icon-loader .path1:before {
  content: "\e961";
  color: #89455e;
}

.icon-loader .path2:before {
  content: "\e962";
  margin-left: -1em;
  color: #89455e;
}

.icon-loader .path3:before {
  content: "\e963";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-loader .path4:before {
  content: "\e964";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-loader .path5:before {
  content: "\e965";
  margin-left: -1em;
  color: #89455e;
}

.icon-loader .path6:before {
  content: "\e966";
  margin-left: -1em;
  color: #89455e;
}

.icon-loader .path7:before {
  content: "\e967";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-loader .path8:before {
  content: "\e968";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-loading .path1:before {
  content: "\e969";
  color: #c1a6b0;
}

.icon-loading .path2:before {
  content: "\e96a";
  margin-left: -1em;
  color: #89455e;
}

.icon-lock:before {
  content: "\e96b";
  color: #c1a6b0;
}

.icon-logout:before {
  content: "\e96c";
}

.icon-logout-hover:before {
  content: "\e96d";
  color: #fff;
}

.icon-money:before {
  content: "\e96e";
}

.icon-phone:before {
  content: "\e975";
  color: #c1a6b0;
}

.icon-receiving .path1:before {
  content: "\e976";
  color: #e6dbdf;
}

.icon-receiving .path2:before {
  content: "\e977";
  margin-left: -1em;
  color: #e6dbdf;
}

.icon-receiving .path3:before {
  content: "\e978";
  margin-left: -1em;
  color: #89455e;
}

.icon-search:before {
  content: "\e979";
  color: #c1a6b0;
}

.icon-settings:before {
  content: "\e97a";
}

.icon-star:before {
  content: "\e982";
  color: #89455e;
}

.icon-star-outline:before {
  content: "\e983";
  color: #89455e;
}

.icon-success:before {
  content: "\e984";
  color: #89455e;
}

.icon-transaction:before {
  content: "\e988";
}

.icon-transaction-disable .path1:before {
  content: "\e989";
  color: #c1a6b0;
}

.icon-transaction-disable .path2:before {
  content: "\e98a";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-transaction-disable .path3:before {
  content: "\e98b";
  margin-left: -1em;
  color: #89455e;
}

.icon-transaction-disable .path4:before {
  content: "\e98c";
  margin-left: -1em;
  color: #89455e;
}

.icon-transaction-empty .path1:before {
  content: "\e98d";
  color: #c1a6b0;
}

.icon-transaction-empty .path2:before {
  content: "\e98e";
  margin-left: -1em;
  color: #c1a6b0;
}

.icon-transaction-empty .path3:before {
  content: "\e98f";
  margin-left: -1em;
  color: #89455e;
}

.icon-transaction-empty .path4:before {
  content: "\e990";
  margin-left: -1em;
  color: #89455e;
}

.icon-transaction-empty .path5:before {
  content: "\e991";
  margin-left: -1em;
  color: #89455e;
}

.icon-transaction-empty .path6:before {
  content: "\e992";
  margin-left: -1em;
  color: #89455e;
}

.icon-transfer .path1:before {
  content: "\e99c";
  color: #e6dbdf;
}

.icon-transfer .path2:before {
  content: "\e99d";
  margin-left: -1em;
  color: #89455e;
}

.icon-unsuccessful .path1:before {
  content: "\e99e";
  color: #c1a6b0;
}

.icon-unsuccessful .path2:before {
  content: "\e99f";
  margin-left: -1em;
  color: #89455e;
}

.icon-unsuccessful .path3:before {
  content: "\e9a0";
  margin-left: -1em;
  color: white;
}

.icon-unsuccessful .path4:before {
  content: "\e9a1";
  margin-left: -1em;
  color: white;
}

.icon-unsuccessful .path5:before {
  content: "\e9a2";
  margin-left: -1em;
  color: white;
}

.icon-unsuccessful .path6:before {
  content: "\e9a3";
  margin-left: -1em;
  color: white;
}

.icon-unsuccessful .path7:before {
  content: "\e9a4";
  margin-left: -1em;
  color: #89455e;
}

.icon-unsuccessful .path8:before {
  content: "\e9a5";
  margin-left: -1em;
  color: #e6dbdf;
}

.icon-unsuccessful .path9:before {
  content: "\e9a6";
  margin-left: -1em;
  color: white;
}

.icon-unsuccessful .path10:before {
  content: "\e9a7";
  margin-left: -1em;
  color: white;
}

.icon-user:before {
  content: "\e9a8";
  color: #c1a6b0;
}

.icon-variant:before {
  content: "\e9a9";
  color: #c1a6b0;
}

.icon-verification:before {
  content: "\e9aa";
  color: #c1a6b0;
}

.icon-withdraw .path1:before {
  content: "\e9ab";
  color: #e6dbdf;
}

.icon-withdraw .path2:before {
  content: "\e9ac";
  margin-left: -1em;
  color: #89455e;
}

.bg-white {
  background-color: #ffffff;
}

.bg-black {
  background-color: #000000;
}

.bg-russian {
  background-color: #161618;
}

.bg-submarine {
  background-color: #989999;
}

.bg-pink {
  background-color: #89455E;
}

.bg-dustpink {
  background-color: #C1A6B0;
}

.bg-prim {
  background-color: #E6DBDF;
}

.bg-amour {
  background-color: #EFE8EB;
}

.bg-gainsboro {
  background-color: #E0DCDC;
}

.bg-blush {
  background-color: #F4F1F2;
}

.clr-white {
  color: #ffffff;
}

.clr-black {
  color: #000000;
}

.clr-russian {
  color: #161618;
}

.clr-submarine {
  color: #989999;
}

.clr-pink {
  color: #89455E;
}

.clr-dustpink {
  color: #C1A6B0;
}

.clr-prim {
  color: #E6DBDF;
}

.clr-amour {
  color: #EFE8EB;
}

.clr-gainsboro {
  color: #E0DCDC;
}

.clr-blush {
  color: #F4F1F2;
}

.mt-16 {
  margin-top: 1.6rem;
}

.mt-18 {
  margin-top: 1.8rem;
}

.mt-20 {
  margin-top: 2rem;
}

.mt-24 {
  margin-top: 2.4rem;
}

.mt-30 {
  margin-top: 3rem;
}

.mt-32 {
  margin-top: 3.2rem;
}

.mt-40 {
  margin-top: 4rem;
}

.mt-50 {
  margin-top: 5rem;
}

.mt-60 {
  margin-top: 6rem;
}

.mt-70 {
  margin-top: 7rem;
}

.mt-80 {
  margin-top: 8rem;
}

.mt-90 {
  margin-top: 9rem;
}

.mt-100 {
  margin-top: 10rem;
}

.mt--1 {
  margin-top: -1rem;
}

.mb-16 {
  margin-bottom: 1.6rem;
}

.mb-18 {
  margin-bottom: 1.8rem;
}

.mb-20 {
  margin-bottom: 2rem;
}

.mb-24 {
  margin-bottom: 2.4rem;
}

.mb-30 {
  margin-bottom: 3rem;
}

.mb-32 {
  margin-bottom: 3.2rem;
}

.mb-40 {
  margin-bottom: 4rem;
}

.mb-50 {
  margin-bottom: 5rem;
}

.mb-60 {
  margin-bottom: 6rem;
}

.mb-70 {
  margin-bottom: 7rem;
}

.mb-80 {
  margin-bottom: 8rem;
}

.mb-90 {
  margin-bottom: 9rem;
}

.mb-100 {
  margin-bottom: 10rem;
}

.mr-19 {
  margin-right: 1.9rem;
}

.mr-20 {
  margin-right: 2rem;
}

.pt-16 {
  padding-top: 1.6rem;
}

.pt-18 {
  padding-top: 1.8rem;
}

.pt-20 {
  padding-top: 2rem;
}

.pt-24 {
  padding-top: 2.4rem;
}

.pt-30 {
  padding-top: 3rem;
}

.pt-32 {
  padding-top: 3.2rem;
}

.pt-40 {
  padding-top: 4rem;
}

.pt-50 {
  padding-top: 5rem;
}

.pt-60 {
  padding-top: 6rem;
}

.pt-70 {
  padding-top: 7rem;
}

.pt-80 {
  padding-top: 8rem;
}

.pt-90 {
  padding-top: 9rem;
}

.pt-100 {
  padding-top: 10rem;
}

.pb-16 {
  padding-bottom: 1.6rem;
}

.pb-18 {
  padding-bottom: 1.8rem;
}

.pb-20 {
  padding-bottom: 2rem;
}

.pb-24 {
  padding-bottom: 2.4rem;
}

.pb-30 {
  padding-bottom: 3rem;
}

.pb-32 {
  padding-bottom: 3.2rem;
}

.pb-40 {
  padding-bottom: 4rem;
}

.pb-50 {
  padding-bottom: 5rem;
}

.pb-60 {
  padding-bottom: 6rem;
}

.pb-70 {
  padding-bottom: 7rem;
}

.pb-80 {
  padding-bottom: 8rem;
}

.pb-90 {
  padding-bottom: 9rem;
}

.pb-100 {
  padding-bottom: 10rem;
}

.fs-12 {
  font-size: 1.2rem;
}

.fs-14 {
  font-size: 1.4rem;
}

.fs-18 {
  font-size: 1.8rem;
}

.fs-24 {
  font-size: 2.4rem;
}

.fs-32 {
  font-size: 3.2rem;
}

.max-300 {
  max-width: 30rem;
}

.max-350 {
  max-width: 30.5rem;
}

.max-400 {
  max-width: 40rem;
}

.max-500 {
  max-width: 50rem;
}

.max-600 {
  max-width: 60rem;
}

.max-700 {
  max-width: 70rem;
}

.max-800 {
  max-width: 80rem;
}

.max-1920 {
  max-width: 192rem;
  margin: auto;
}

.max-980 {
  max-width: 98rem;
}

.mwidth_605 {
  max-width: 60.5rem;
  width: 100%;
}

.minvh-100 {
  min-height: 100vh;
}

.minh-350 {
  min-height: 35rem;
}

.minh-265 {
  min-height: 26.5rem;
}

.minh-500 {
  min-height: 50rem;
}

.minh-row {
  min-height: calc(100vh - 3.2rem);
  margin: 0;
}

.minh-row .bo-sidebar-col,
.minh-row .bo-right-sidebar {
  padding: 0;
}

.minh-row-mbl {
  min-height: calc(100vh - 8rem);
}

.mwidth-42 {
  max-width: 42rem;
  width: 100%;
}

.mwidth-76 {
  max-width: 76rem;
  width: 100%;
}
.mwidth-86 {
  max-width: 96rem;
  width: 100%;
}

.cur-pointer {
  cursor: pointer;
}

.toRight {
  text-align: right;
}

::-moz-selection {
  background-color: #E0DCDC;
  color: #89455E;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

::selection {
  background-color: #E0DCDC;
  color: #89455E;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.bo-badge {
  border-radius: 0.4rem;
  background-color: #89455E;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 1rem;
}

.bo-badge.bo-badge-large {
  padding: 0.06rem 0.41rem;
  position: relative;
  top: -0.15rem;
}

.carousel-indicators {
  bottom: -1.7rem;
}

.carousel-indicators [data-bs-target] {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 100%;
  background-color: #C1A6B0;
  opacity: 0.6;
}

.carousel-indicators .active {
  opacity: 1;
  width: 1.3rem;
  background-color: #89455E;
  border-radius: 45%;
}

.carousel-control-prev {
  left: -3rem;
}

.carousel-control-next {
  right: -3rem;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1;
}

.prev-icon,
.next-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background: white;
  -webkit-box-shadow: 0.2rem 0.4rem 0.4rem rgba(193, 166, 176, 0.24);
          box-shadow: 0.2rem 0.4rem 0.4rem rgba(193, 166, 176, 0.24);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none;
}

.a-arrow-btn a {
  color: #89455E !important;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
}

.a-arrow-btn a img,
.a-arrow-btn a span {
  font-size: 2.5rem;
  width: 0.5rem;
  position: relative;
  top: 0.57rem;
  left: -0.2rem;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.a-arrow-btn a img::before,
.a-arrow-btn a span::before {
  color: #89455E;
}

.a-arrow-btn a:hover img,
.a-arrow-btn a:hover span {
  -webkit-transform: translateX(0.3rem);
          transform: translateX(0.3rem);
  left: 0.2rem;
}

.css-1uqia1w,
.css-1w5wz89 {
  background-color: #89455E !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 2rem;
  margin-right: 1.5rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: url(../../assets/images/icons/disc.svg);
  height: 2rem;
  width: 2rem;
  left: -0.5rem;
  bottom: 0.6rem;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffffff;
}

input:focus + .slider {
  -webkit-box-shadow: 0 0 0.1rem #989999;
          box-shadow: 0 0 0.1rem #989999;
}

input:checked + .slider:before {
  -webkit-transform: translateX(2rem);
  transform: translateX(2rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 1.6rem;
}

.user-profile-drop::-webkit-scrollbar {
  width: 0.3rem;
}

/* Track */
.user-profile-drop::-webkit-scrollbar-track {
  background: #989999;
}

/* Handle */
.user-profile-drop::-webkit-scrollbar-thumb {
  background: #161618;
}

/* Handle on hover */
.user-profile-drop::-webkit-scrollbar-thumb:hover {
  background: #161618;
}

@font-face {
  font-family: "icomoon";
  src: url("../../assets/fonts/icomoon.eot?4r2wx3");
  src: url("../../assets/fonts/icomoon.eot?4r2wx3#iefix") format("embedded-opentype"), url("../../assets/fonts/icomoon.ttf?4r2wx3") format("truetype"), url("../../assets/fonts/icomoon.woff?4r2wx3") format("woff"), url("../../assets/fonts/icomoon.svg?4r2wx3#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 2.4rem;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 10px;
}

html body {
  color: #161618;
  background-color: #F4F1F2;
  font-family: "Inter", Arial;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;
  min-height: 100vh;
}

html body a {
  color: #161618;
  text-decoration: none;
  outline: none !important;
}

html body a:hover {
  color: #161618;
  text-decoration: none;
}

html body h1,
html body h2,
html body h3,
html body h4,
html body h5,
html body h6 {
  font-family: "Inter", Arial;
  margin-bottom: 0;
  color: #161618;
}

html body h1 {
  font-size: 3.2rem;
  font-weight: 800;
  line-height: 4rem;
}

html body h2 {
  font-size: 2.4rem;
  font-weight: 800;
  line-height: 3.2rem;
}

html body h3 {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 2.2rem;
}

html body h4 {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
}

html body h5 {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
}

html body h6 {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
}

html body .btn,
html body input[type="submit"] {
  color: #ffffff;
  background-color: #161618;
  font-size: 1.8rem;
  border: none;
  border-radius: 0.8rem;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none !important;
  padding: 1.3rem 1.2rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-width: 15rem;
  line-height: 1.85rem;
  font-weight: 700;
}

html body .btn:hover, html body .btn:focus,
html body input[type="submit"]:hover,
html body input[type="submit"]:focus {
  color: #ffffff;
  background-color: #C1A6B0;
}

html body .btn.btn[disabled], html body .btn.disabled,
html body input[type="submit"].btn[disabled],
html body input[type="submit"].disabled {
  background-color: #E0DCDC;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

html body .btn.btn[disabled]:hover, html body .btn.btn[disabled]:focus, html body .btn.disabled:hover, html body .btn.disabled:focus,
html body input[type="submit"].btn[disabled]:hover,
html body input[type="submit"].btn[disabled]:focus,
html body input[type="submit"].disabled:hover,
html body input[type="submit"].disabled:focus {
  background-color: #E0DCDC;
}

html body .btn.btn-extra-large,
html body input[type="submit"].btn-extra-large {
  font-weight: 600;
  padding: 1rem 2.3rem;
  height: 4rem;
  font-size: 1.4rem;
}

html body .btn.btn-16,
html body input[type="submit"].btn-16 {
  font-size: 1.6rem;
}

html body textarea {
  resize: none;
}

html body .btn-outline {
  color: #161618;
  background-color: transparent;
  border: 0.1rem solid #161618;
  font-size: 1.8rem;
  border-radius: 0.8rem;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none !important;
  padding: 1.3rem 1.2rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-width: 15rem;
  line-height: 1.93rem;
  font-weight: 700;
}

html body .btn-outline.btn-outline-large {
  font-weight: 600;
  padding: 0.9rem 2.3rem;
  height: 4rem;
  font-size: 1.4rem;
}

html body .btn-outline:hover, html body .btn-outline:focus {
  color: #89455E;
  border-color: #89455E;
  background-color: transparent;
}

html body .btn-outline.disabled {
  color: #E0DCDC;
  background-color: #E0DCDC;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

html body .btn-outline.disabled:hover, html body .btn-outline.disabled:focus {
  background-color: #E0DCDC;
}

html body .text-btn {
  color: #161618;
  background-color: transparent;
  font-size: 1.4rem;
  outline: none !important;
  border: none;
  letter-spacing: normal;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

html body .text-btn i {
  font-size: 1.6rem;
  margin-right: 0.8rem;
}

html body .text-btn:focus, html body .text-btn:active, html body .text-btn:hover {
  color: #89455E;
}

html body .text-btn:focus i::before, html body .text-btn:active i::before, html body .text-btn:hover i::before {
  color: #89455E;
}

html body .text-btn.disabled {
  color: #E0DCDC;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

html body .text-btn.disabled i {
  color: #E0DCDC;
}

html body .text-btn.disabled:hover, html body .text-btn.disabled:focus {
  color: #E0DCDC;
}

html body form label {
  color: #161618;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 0.5rem;
}

html body form .form-group .form-control,
html body form select,
html body form textarea,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
  color: #161618;
  background-color: #ffffff;
  font-size: 1.4rem;
  border-radius: 0.8rem;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0.1rem solid #C1A6B0;
  outline: 0;
  padding: 1.2rem 1.6rem 1.2rem 5.2rem;
  font-weight: 500;
}

html body form .form-group .form-control.no-icon,
html body form select.no-icon,
html body form textarea.no-icon,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]).no-icon {
  padding: 1.2rem 1.6rem 1.2rem;
}

html body form .form-group .form-control.right-icon,
html body form select.right-icon,
html body form textarea.right-icon,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]).right-icon {
  padding: 1.2rem 5rem 1.2rem 1.6rem;
}

html body form .form-group .form-control.double-icon,
html body form select.double-icon,
html body form textarea.double-icon,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]).double-icon {
  padding: 1.2rem 5rem;
}

html body form .form-group .form-control.dollar-icon,
html body form select.dollar-icon,
html body form textarea.dollar-icon,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]).dollar-icon {
  padding: 1.2rem 1.6rem 1.2rem 2.9rem;
}

html body form .form-group .form-control.custom-dropdown,
html body form select.custom-dropdown,
html body form textarea.custom-dropdown,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]).custom-dropdown {
  padding: 0.42rem 1rem 0.42rem 1.6rem;
}

html body form .form-group .form-control.file-control label,
html body form select.file-control label,
html body form textarea.file-control label,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]).file-control label {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0;
}

html body form .form-group .form-control::-webkit-input-placeholder,
html body form select::-webkit-input-placeholder,
html body form textarea::-webkit-input-placeholder,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"])::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #989999;
  opacity: 1;
  /* Firefox */
}

html body form .form-group .form-control:-ms-input-placeholder,
html body form select:-ms-input-placeholder,
html body form textarea:-ms-input-placeholder,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]):-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #989999;
  opacity: 1;
  /* Firefox */
}

html body form .form-group .form-control::-ms-input-placeholder,
html body form select::-ms-input-placeholder,
html body form textarea::-ms-input-placeholder,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"])::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #989999;
  opacity: 1;
  /* Firefox */
}

html body form .form-group .form-control::placeholder,
html body form select::placeholder,
html body form textarea::placeholder,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"])::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #989999;
  opacity: 1;
  /* Firefox */
}

html body form .form-group .form-control:-ms-input-placeholder,
html body form select:-ms-input-placeholder,
html body form textarea:-ms-input-placeholder,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]):-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #989999;
}

html body form .form-group .form-control::-ms-input-placeholder,
html body form select::-ms-input-placeholder,
html body form textarea::-ms-input-placeholder,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"])::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #989999;
}

html body form .form-group .form-control.error,
html body form select.error,
html body form textarea.error,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]).error {
  color: #89455E;
  border: 0.1rem solid #89455E;
}

html body form .form-group .form-control:disabled,
html body form select:disabled,
html body form textarea:disabled,
html body form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]):disabled {
  background-color: #F4F1F2;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

html body form .form-controls {
  position: relative;
}

html body form .form-controls ._ico {
  position: absolute;
  top: 1.2rem;
  left: 1.8rem;
}

html body form .form-controls ._ico::before {
  color: #C1A6B0;
}

html body form .form-controls ._ico.icon-canada {
  top: 1.45rem;
  font-size: 1.7rem;
}

html body form .form-controls ._ico.icon-upload-file {
  top: 1.45rem;
  font-size: 1.7rem;
}

html body form .form-controls ._ico.icon-right {
  left: auto;
  right: 1.8rem;
}

html body form .form-controls ._ico.dollar {
  top: 1.4rem;
}

html body form .form-controls.form-card-state select {
  padding: 0.8rem 1.2rem 0.7rem;
  max-width: 15rem;
  margin-left: auto;
}

html body form .show-password,
html body form .icon-round {
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  -webkit-filter: drop-shadow(0.4rem 0.4rem 0.4rem rgba(22, 22, 24, 0.16));
          filter: drop-shadow(0.4rem 0.4rem 0.4rem rgba(22, 22, 24, 0.16));
  font-size: 2.4rem;
  cursor: pointer;
  right: 1.5rem;
  top: 1rem;
  z-index: 2;
}

html body form .show-password:before,
html body form .icon-round:before {
  top: 0.3rem;
  left: 0.2rem;
  position: relative;
  color: #161618;
}

html body form .show-password {
  z-index: 1;
}

html body form .icon-round {
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.4rem;
}

html body form .icon-round:before {
  top: 0.6rem;
  left: 0.5rem;
  color: #C1A6B0;
}

html body form select,
html body form .custom-select {
  background-image: url("../../assets/images/darrow.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 1.5rem) center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  text-overflow: ellipsis;
}

html body form.form-dashboard .form-controls input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
  border: none;
}

html body form.form-dashboard .form-controls ._ico {
  color: #C1A6B0;
}

html body form .form-group {
  margin-bottom: 2.8rem;
  position: relative;
}

html body form .form-group b {
  font-size: 1.6rem;
  font-weight: 700;
}

html body form .form-group.mb-16 {
  margin-bottom: 1.6rem;
}

html body form .form-group.authentications .form-controls input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
  font-size: 1.6rem;
  margin-right: 1.1rem;
  text-align: center;
  padding: 1.2rem 1rem;
  height: 4.2rem;
  width: 4.2rem;
}

html body form .form-group.form-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

html body form .form-group.form-search label {
  width: 40%;
  color: #989999;
}

html body form .form-group.form-search .form-controls {
  width: 60%;
}

html body form .form-group.form-search .form-controls input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
html body form .form-group.form-search .form-controls form select {
  border: none;
  border-radius: 0;
  border-bottom: 0.1rem solid #E0DCDC;
  padding: 0;
  padding-bottom: 0.5rem;
}

html body form .form-group.form-search .form-controls input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]):disabled,
html body form .form-group.form-search .form-controls form select:disabled {
  border: none;
  background-color: transparent !important;
}

html body form .form-group.form-search.submit-field {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-bottom: 0;
}

html body form .mb-20 {
  margin-bottom: 2rem;
}

html body form .mb-40 {
  margin-bottom: 4rem;
}

html body form .form-link a {
  color: #89455E;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: underline;
}

html body form .form-checkbox input[type="checkbox"] {
  display: none;
}

html body form .form-checkbox label {
  position: relative;
  padding-left: 2.8rem;
  line-height: 1.9rem;
  font-size: 1.2rem;
  margin-bottom: 0;
  width: 100%;
}

html body form .form-checkbox label a {
  font-weight: 600;
}

html body form .form-checkbox label a:hover {
  text-decoration: underline;
}

html body form .form-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 0.1rem solid #C1A6B0;
  border-radius: 0.4rem;
  position: absolute;
  top: 0.2rem;
  left: 0rem;
  cursor: pointer;
  height: 1.6rem;
  width: 1.6rem;
}

html body form .form-checkbox label:after {
  content: "";
  border: solid #C1A6B0;
  border-width: 0 0.1rem 0.1rem 0;
  position: absolute;
  top: 0.5rem;
  left: 0.6rem;
  height: 0.8rem;
  width: 0.4rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  opacity: 0;
}

html body form .form-checkbox input:checked + label:after {
  opacity: 1;
}

html body form .field-checkbox input[type="checkbox"] {
  display: none;
}

html body form .field-checkbox label {
  position: relative;
  padding-left: 3.2rem;
  line-height: 1.8rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  width: 100%;
}

html body form .field-checkbox label a {
  font-weight: 600;
}

html body form .field-checkbox label a:hover {
  text-decoration: underline;
}

html body form .field-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 0.1rem solid #161618;
  border-radius: 0.4rem;
  position: absolute;
  top: 0.2rem;
  left: 0rem;
  cursor: pointer;
  height: 1.6rem;
  width: 1.6rem;
}

html body form .field-checkbox label:after {
  content: "";
  border: solid #161618;
  border-width: 0 0.1rem 0.1rem 0;
  position: absolute;
  top: 0.5rem;
  left: 0.6rem;
  height: 0.8rem;
  width: 0.4rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  opacity: 0;
}

html body form .field-checkbox input:checked + label:after {
  opacity: 1;
}

html body form .has_error {
  color: #89455E;
}

html body form .has_error .form-control,
html body form .has_error select,
html body form .has_error textarea,
html body form .has_error input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
  color: #89455E;
  border-color: #89455E;
}

html body form .has_error span {
  color: #89455E;
}

html body form .has_error .help-block {
  font-size: 1.2rem;
  margin-top: 0.6rem;
  display: block;
  font-weight: 400;
}

html body form .icon-calender-mini::before {
  color: #161618;
}

html body .alert-messages {
  background: #F4F1F2;
  border-radius: 0.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1.2rem 2.9rem;
}

html body .alert-messages span {
  margin-right: 1.1rem;
}

html body .alert-messages p {
  color: #89455E;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 120%;
  margin-bottom: 0;
}

html body .cross-icon {
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

html body .cross-icon:hover {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

html body .cross-icon.cross-modal {
  position: absolute;
  top: 2.3rem;
  right: 2.3rem;
  z-index: 3;
}

html body .modal {
  overflow-y: auto;
}

html body .modal.show {
  padding-left: 0;
}

html body .modal .modal-dialog .modal-content {
  background: #ffffff;
  -webkit-box-shadow: 0.4rem 0.4rem 3rem rgba(193, 166, 176, 0.5);
          box-shadow: 0.4rem 0.4rem 3rem rgba(193, 166, 176, 0.5);
  border-radius: 1.5rem;
  border: 0;
}

html body .modal .modal-dialog .modal-footer {
  border-top: 0;
}

html body hr {
  border-color: #E0DCDC;
}

html body .progress {
  display: inline-block;
  min-width: 8rem;
  height: 0.5rem;
  background-color: #E6DBDF;
  border-radius: 10rem;
}

html body .progress .progress-bar {
  background-color: #89455E;
  display: list-item;
}

html body table td[class*="col-"],
html body table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}

html body .bo-table th {
  border-bottom: 0.1rem solid #E0DCDC;
  font-size: 1.2rem;
  color: #89455E;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0;
  padding-bottom: 0.9rem;
}

html body .bo-table th:last-child {
  text-align: right;
}

html body .bo-table td {
  font-size: 1.4rem;
  padding: 1.4rem 0;
  line-height: 1.9rem;
  vertical-align: middle;
}

html body .bo-table td span span {
  font-size: 1.4rem;
  padding-right: 0.5rem;
}

html body .bo-table td img {
  width: 4rem;
  /* height: 4rem; */
  overflow: hidden;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 1rem;
}

html body .bo-table td span {
  display: inline-block;
}

html body .bo-table tr:nth-child(1) td {
  padding-top: 2.3rem;
}

html body .bo-table tr th:last-child,
html body .bo-table tr td:last-child {
  text-align: right;
}

html body .bo-table tr th:last-child span,
html body .bo-table tr td:last-child span {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 100%;
  background-color: #F4F1F2;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

html body .bo-table tr th:last-child span::before,
html body .bo-table tr td:last-child span::before {
  position: relative;
  top: 0.2rem;
  left: 0.2rem;
}

html body .bo-table tr th:last-child span:hover::before,
html body .bo-table tr td:last-child span:hover::before {
  color: #89455E;
}

html body .bo-table.table-details td {
  padding: 0.2rem 0 0.8rem;
}

html body .bo-table.table-sendMoney td {
  padding: 0.4rem 0 0.5rem;
}

html body .td-logo {
  width: 4rem;
  /* height: 4rem; */
  background-color: #EFE8EB;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 1rem;
}

html body .nav-tabs {
  border-bottom: 0.2rem solid #E6DBDF;
}

html body .nav-tabs .nav-item {
  margin-right: 2rem;
}

html body .nav-tabs .nav-item .nav-link {
  color: #C1A6B0;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0rem 1rem 2.5rem 1rem;
  border: none;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  margin-bottom: -0.2rem;
}

html body .nav-tabs .nav-item .nav-link.disabled {
  color: #989999;
}

html body .nav-tabs .nav-item .nav-link.disabled span:before {
  color: #989999;
}

html body .nav-tabs .nav-item .nav-link.active {
  color: #89455E;
  border-bottom: 0.2rem solid #89455E;
}

html body .nav-tabs .nav-item .nav-link.active [class^="icon-"]::before,
html body .nav-tabs .nav-item .nav-link.active [class*=" icon-"]::before {
  color: #89455E;
}

html body .nav-tabs .nav-item .nav-link [class^="icon-"]::before,
html body .nav-tabs .nav-item .nav-link [class*=" icon-"]::before {
  color: #C1A6B0;
}

html body .nav-tabs .nav-item:last-child {
  margin-right: 0;
}

html body .nav-tabs.nav-tabs-profile .nav-item {
  margin-right: 1rem;
}

html body .nav-tabs.nav-tabs-profile .nav-item .nav-link {
  padding: 0rem 5rem 2.5rem 1rem;
}

html body .nav-tabs.nav-tabs-client .nav-item {
  margin-right: 1rem;
}

html body .nav-tabs.nav-tabs-client .nav-item .nav-link {
  padding: 0rem 3rem 2.5rem 3rem;
}

html body .tab-content {
  padding-top: 2.2rem;
}

html body .bo-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

html body .bo-checkbox label {
  position: relative;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1.9rem;
  color: #000000;
  margin-bottom: 0;
}

html body .bo-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  border: 0px;
  padding: 0.7rem;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 1rem;
  border-radius: 0.2rem;
  background-color: white;
  border: solid 0.15rem #C1A6B0;
  margin-bottom: 0.3rem;
}

html body .bo-checkbox input:checked + label:before {
  background-color: #ffffff;
  border: solid 0.1rem #C1A6B0;
}

html body .bo-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 0.5rem;
  height: 1rem;
  border: solid #C1A6B0;
  border-width: 0 0.2rem 0.2rem 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

html body .bo-checkbox.bo-checkbox-round label:before {
  padding: 0.85rem;
  border-radius: 100%;
  background-color: #ffffff;
  border: 0.15rem solid #C1A6B0;
}

html body .bo-checkbox.bo-checkbox-round input:checked + label:before {
  background-color: #89455E;
  border: solid 0.15rem #89455E;
}

html body .bo-checkbox.bo-checkbox-round input:checked + label:after {
  border: solid #ffffff;
  border-width: 0 0.16rem 0.16rem 0;
  margin: auto;
  top: 0.31rem;
  left: 0.7rem;
  width: 0.44rem;
  height: 1.1rem;
}

html body .loading-more {
  text-align: center;
}

html body .loading-more .loader {
  position: relative;
  margin: auto auto 0.5rem auto;
  height: 2rem;
  width: 4rem;
}

html body .loading-more .loader > div, html body .loading-more .loader:before, html body .loading-more .loader:after {
  -webkit-animation: load 1.4s ease-in-out infinite;
          animation: load 1.4s ease-in-out infinite;
  background-color: #989999;
  border-radius: 50%;
  content: "";
  height: 0.3rem;
  width: 0.3rem;
}

html body .loading-more .loader > div {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  left: 1.8rem;
  top: 0.4rem;
  position: absolute;
}

html body .loading-more .loader:before {
  left: 1.2rem;
  top: 0.4rem;
  position: absolute;
}

html body .loading-more .loader:after {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
  left: 2.4rem;
  top: 0.4rem;
  position: absolute;
}

html body .loading-more p {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 0.15rem;
}

@-webkit-keyframes load {
  50% {
    -webkit-transform: translateY(0.4em);
            transform: translateY(0.4em);
  }
}

@keyframes load {
  50% {
    -webkit-transform: translateY(0.4em);
            transform: translateY(0.4em);
  }
}

.hamburgers {
  background: transparent;
  border: none;
  margin-right: 1.8rem;
  height: 1.8rem;
  position: relative;
  width: 2.2rem;
  padding: 0;
  top: 0.1rem;
  outline: none !important;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.hamburgers:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
}

.hamburgers span {
  display: block;
  position: absolute;
  border-radius: 0.5rem;
  height: 0.15rem;
  width: 100%;
  background: #161618;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.hamburgers span:nth-child(1) {
  top: 0;
}

.hamburgers span:nth-child(2) {
  top: 0.8rem;
  width: 75%;
}

.hamburgers span:nth-child(3) {
  top: 1.6rem;
}

.hamburgers[aria-expanded="true"] span:nth-child(1) {
  top: 0.8rem;
  width: 0%;
  left: 50%;
}

.hamburgers[aria-expanded="true"] span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburgers[aria-expanded="true"] span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 0.8rem;
}

.chart-wrap {
  position: relative;
  width: 15rem;
  height: 15rem;
}

.chart-wrap > div:first-child {
  position: relative;
  top: -3.5rem;
  left: -4.2rem;
}

.chart-wrap svg rect {
  fill: transparent;
}

.chart-wrap svg g path {
  stroke-width: 0;
}

.donut-center-text {
  position: absolute;
  margin: auto;
  top: 5.7rem;
  left: -1.4rem;
  right: 0;
  text-align: center;
}

.donut-center-text h5 {
  font-size: 1.6rem;
  font-weight: 700;
  max-width: 8rem;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.donut-center-text p {
  font-size: 1.2rem;
  font-weight: 600;
  color: #989999;
}

.no-caret.dropdown-toggle::after {
  display: none;
}

.cur-default {
  cursor: default;
}

.icon-add-client {
  padding: 1rem 0;
  font-size: 80px;
}

.icon-add-client .path1:before {
  color: #C1A6B0;
}

.icon-add-client .path2:before {
  color: #C1A6B0;
}

.icon-add-client .path3:before {
  color: #89455E;
}

.icon-add-client .path4:before {
  color: #E6DBDF;
}

.icon-load-card {
  padding: 1rem 0;
  font-size: 80px;
}

.icon-load-card .path1:before {
  color: #C1A6B0;
}

.icon-load-card .path8::before {
  color: #89455E;
}

.icon-load-card .path9::before {
  color: #E6DBDF;
}

.icon-issued-card {
  padding: 1rem 0;
  font-size: 8rem;
}

.icon-issued-card .path1:before {
  color: #C1A6B0;
}

.icon-issued-card .path9::before {
  color: #89455E;
}

.icon-issued-card .path10::before {
  color: #E6DBDF;
}

.icon-clients-empty {
  font-size: 80px;
}

.icon-clients-empty .path1::before,
.icon-clients-empty .path2::before,
.icon-clients-empty .path3::before {
  color: #89455E;
}

.icon-clients-empty .path4::before,
.icon-clients-empty .path5::before,
.icon-clients-empty .path6::before {
  color: #C1A6B0;
}

.icon-upload-file .path1::before {
  color: #C1A6B0;
}

.icon-upload-file .path2::before {
  color: #89455E;
}

.icon-receiving {
  font-size: 40px;
  font-style: italic;
}

.icon-receiving .path1::before,
.icon-receiving .path2::before {
  color: #E6DBDF;
  font-style: normal;
}

.icon-receiving .path3::before {
  color: #89455E;
  font-style: normal;
}

.icon-withdraw,
.icon-transfer {
  font-size: 40px;
  font-style: normal;
}

.icon-withdraw .path1::before,
.icon-transfer .path1::before {
  color: #E6DBDF;
  font-style: normal;
}

.icon-withdraw .path2::before,
.icon-transfer .path2::before {
  color: #89455E;
  font-style: normal;
}

.icon-lock:before {
  color: #C1A6B0;
}

.icon-Credit-Limit,
.icon-Online-Limit,
.icon-Transfer-Limit,
.icon-ATM-Limit {
  font-size: 32px;
}

.icon-Credit-Limit .path1::before,
.icon-Online-Limit .path1::before,
.icon-Transfer-Limit .path1::before,
.icon-ATM-Limit .path1::before {
  color: #E6DBDF;
  opacity: 1;
}

.icon-Credit-Limit .path2::before,
.icon-Online-Limit .path2::before,
.icon-Transfer-Limit .path2::before,
.icon-ATM-Limit .path2::before {
  color: #89455E;
}

.icon-loader {
  font-size: 68px;
}

.icon-loader .path1::before,
.icon-loader .path2::before,
.icon-loader .path5::before,
.icon-loader .path6::before {
  color: #89455E;
}

.icon-loader .path3::before,
.icon-loader .path4::before,
.icon-loader .path7::before,
.icon-loader .path8::before {
  color: #C1A6B0;
}

.icon-transaction-empty {
  font-size: 80px;
}

.icon-transaction-empty .path1::before,
.icon-transaction-empty .path2::before {
  color: #C1A6B0;
}

.icon-transaction-empty .path3::before,
.icon-transaction-empty .path4::before,
.icon-transaction-empty .path5::before,
.icon-transaction-empty .path6::before {
  color: #89455E;
}

.icon-deposit-emptystate {
  font-size: 80px;
}

.icon-deposit-emptystate .path1::before,
.icon-deposit-emptystate .path2::before,
.icon-deposit-emptystate .path3::before,
.icon-deposit-emptystate .path4::before,
.icon-deposit-emptystate .path5::before {
  color: #C1A6B0;
}

.icon-deposit-emptystate .path6::before {
  color: #89455E;
}

.icon-deposit-emptystate .path7::before {
  color: #E6DBDF;
}

.icon-info:before {
  color: #89455E;
}

.icon-successful .path1::before,
.icon-error .path1::before {
  color: #E6DBDF;
}

.icon-successful .path2::before,
.icon-error .path2::before {
  color: #89455E;
}

::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #cecccc;
  border: 0px none #ffffff;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cecccc;
}

::-webkit-scrollbar-thumb:active {
  background: #cecccc;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none transparent;
  border-radius: 0px;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.onbo-sidebar {
  padding: 0;
}

.onbo-sidebar .onbo-inn {
  background-image: url("../images/particles-web-theme1.svg");
  background-position: center bottom;
  background-repeat: no-repeat;
  padding: 6rem;
  max-width: 100%;
  margin: auto 0 auto auto;
  position: relative;
  height: 100vh;
}

.onbo-sidebar .onbo-inn .bo-info h4 {
  color: #89455E;
  font-weight: 600;
  margin-top: 1.6rem;
}

.bo-thumb h3 {
  line-height: 2.17rem;
  font-weight: 900;
}

.bo-thumb h3 span {
  color: #989999;
  font-weight: 700;
}

.onbo-content {
  background-color: #ffffff;
  -webkit-box-shadow: 0rem 0.8rem 2rem rgba(193, 166, 176, 0.24);
          box-shadow: 0rem 0.8rem 2rem rgba(193, 166, 176, 0.24);
  border-radius: 3.2rem 0rem 0rem 3.2rem;
  padding: 7rem 1.5rem 2rem 10rem;
  position: relative;
}

.onbo-content .bo-on-screens {
  padding-top: 10rem;
  max-width: 80rem;
}

.onbo-content .alert-messages {
  position: absolute;
  left: 0;
  top: 0;
}

.icon-alert {
  margin-bottom: 4rem;
}

.icon-alert span {
  font-size: 8rem;
}

.icon-alert.icon-alert-popup {
  margin-bottom: 2.4rem;
}

.bo-file {
  background-color: #F4F1F2;
  border-radius: 0.8rem;
  padding: 1.1rem 1.6rem;
  margin-top: 2.3rem;
}

.bo-file .file-name h5 {
  margin-bottom: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 1.8rem;
  padding-right: 1rem;
}

.bo-file .file-name h5 span {
  max-width: 10rem;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.bo-file .file-size {
  margin-left: auto;
  margin-top: 0.3rem;
}

.bo-file .file-size span {
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #89455E;
  margin-right: 2.4rem;
}

.bo-file-control {
  position: relative;
}

.bo-file-control .file-control-input {
  position: relative;
  z-index: 1;
  background-color: transparent !important;
  opacity: 0;
}

.bo-file-control .file-control-text {
  position: absolute;
  top: 0;
}

.smallPopup .modal-body {
  padding: 6rem 5.2rem 6.2rem;
}

.bo-app-main {
  padding: 1.6rem 3.5rem 1.6rem 0rem;
}

.bo-app-main-double-sidebar {
  padding: 1.6rem 0rem 1.6rem 0rem;
}

.bo-sidebar-col {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.bo-sidebar-outer {
  padding: 5.3rem 3rem 5.3rem 4.5rem;
  max-width: 27rem;
  margin: auto;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.bo-sidebar-outer .sidebar-ul {
  list-style-type: none;
  padding: 0;
  margin-top: 3rem;
}

.bo-sidebar-outer .sidebar-ul li {
  text-align: left;
  margin-left: -1.3rem;
}

.bo-sidebar-outer .sidebar-ul li a {
  font-weight: 500;
  color: #161618;
  line-height: 1.9rem;
  padding: 0.8rem 1.3rem 0.8rem 1.1rem;
  border-radius: 0.8rem;
  margin-bottom: 0.2rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.bo-sidebar-outer .sidebar-ul li a span.bo-badge {
  padding: 0.3rem 0.43rem;
  line-height: 1rem;
}

.bo-sidebar-outer .sidebar-ul li a i {
  margin-right: 1.5rem;
}

.bo-sidebar-outer .sidebar-ul li a.active, .bo-sidebar-outer .sidebar-ul li a:hover {
  background-color: #161618;
  color: #ffffff;
}

.bo-sidebar-outer .sidebar-ul li a.active i.icon-dashboard::before, .bo-sidebar-outer .sidebar-ul li a:hover i.icon-dashboard::before {
  content: "\e913";
}

.bo-sidebar-outer .sidebar-ul li a.active i.icon-client, .bo-sidebar-outer .sidebar-ul li a:hover i.icon-client {
  margin-right: 1.9rem;
}

.bo-sidebar-outer .sidebar-ul li a.active i.icon-client::before, .bo-sidebar-outer .sidebar-ul li a:hover i.icon-client::before {
  content: "\e987";
  font-size: 2rem;
}

.bo-sidebar-outer .sidebar-ul li a.active i.icon-card::before, .bo-sidebar-outer .sidebar-ul li a:hover i.icon-card::before {
  content: "\e9ae";
}

.bo-sidebar-outer .sidebar-ul li a.active i.icon-transaction::before, .bo-sidebar-outer .sidebar-ul li a:hover i.icon-transaction::before {
  content: "\e90f";
}

.bo-sidebar-outer .sidebar-ul li a.active i.icon-deposit::before, .bo-sidebar-outer .sidebar-ul li a:hover i.icon-deposit::before {
  content: "\e92a";
}

.bo-sidebar-outer .sidebar-ul li a.active i.icon-settings::before, .bo-sidebar-outer .sidebar-ul li a:hover i.icon-settings::before {
  content: "\e929";
}

.bo-sidebar-outer .sidebar-ul li a.active i.icon-help::before, .bo-sidebar-outer .sidebar-ul li a:hover i.icon-help::before {
  content: "\e90e";
}

.bo-sidebar-outer .sidebar-ul li a.active i.icon-money::before, .bo-sidebar-outer .sidebar-ul li a:hover i.icon-money::before {
  content: "\e93d";
}

.bo-sidebar-outer .sidebar-ul li a.active i.icon-RoleManagement::before, .bo-sidebar-outer .sidebar-ul li a:hover i.icon-RoleManagement::before {
  content: "\e996";
}

.bo-sidebar-outer .sidebar-ul li a.active i.icon-ChangePIN::before, .bo-sidebar-outer .sidebar-ul li a:hover i.icon-ChangePIN::before {
  content: "\e995";
}

.bo-sidebar-outer .sidebar-ul li a.logout i {
  margin-right: 1.5rem;
}

.bo-sidebar-outer .sidebar-ul li a.logout span.user-name {
  color: #89455E;
  font-size: 1.2rem;
  font-weight: 500;
}

.bo-sidebar-outer .sidebar-ul hr {
  margin-left: -1.3rem;
  margin-top: 2.4rem;
}

.header-main-outer {
  background-color: #ffffff;
  border-bottom: 0.1rem solid #E6DBDF;
  padding: 1.6rem 2rem;
}

.header-main-outer .div-hamburger {
  position: absolute;
  left: 0.5rem;
}

.bo-main-content {
  background: #ffffff;
  -webkit-box-shadow: 0rem 0.8rem 2rem rgba(193, 166, 176, 0.24);
          box-shadow: 0rem 0.8rem 2rem rgba(193, 166, 176, 0.24);
  border-radius: 1.6rem;
  padding: 4rem 3.2rem;
}

.bo-main-content.bo-main-nopad {
  padding: 4rem 0 0;
}

.bo-main-content .bo-main-content-sub {
  padding: 2rem 3.2rem;
}

.col-stats {
  background-color: #F4F1F2;
  border-radius: 1.2rem;
  min-height: calc(100% - 4rem);
}

.col-stats.col-stats-chart {
  padding: 1.5rem 3.2rem;
}

.col-stats.col-stats-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 2rem;
}

.col-stats.col-stats-action div {
  margin-top: -1rem;
  text-align: center;
  cursor: pointer;
}

.col-stats.col-stats-action div img {
  margin-bottom: 1.3rem;
}

.col-stats.col-stats-action div a {
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.col-stats.col-stats-action div a img, .col-stats.col-stats-action div a span {
  margin-left: 0.4rem;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 1.7rem;
}

.col-stats.col-stats-action div:hover a img, .col-stats.col-stats-action div:hover a span {
  -webkit-transform: translateX(0.7rem);
          transform: translateX(0.7rem);
}

.col-stats.col-stats-card {
  background-image: url(../images/dashboard-card.svg);
  background-size: cover;
  width: 100%;
  padding: 1rem 2.4rem 2rem;
  position: relative;
}

.col-stats.col-stats-card p {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
}

.col-stats.col-stats-card img {
  position: absolute;
  right: 1.8rem;
  bottom: 1.3rem;
}

.col-stats.col-ledger {
  width: auto;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

.col-stats .chart-legends {
  margin-left: 3rem;
}

.col-stats .chart-legends div p {
  font-size: 1.2rem;
  font-weight: 600;
  color: #989999;
  margin-bottom: 0.3rem;
}

.col-stats .chart-legends div h5 {
  margin-left: -1.3rem;
}

.col-stats .chart-legends div h5 span {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
  background-color: #89455E;
  margin-right: 0.5rem;
  display: inline-block;
}

.col-stats .chart-legends div:nth-child(2) h5 span {
  background-color: #E0DCDC;
}

.col-stats .chart-legends.chart-legends-deposit {
  margin-left: 0;
}

.col-stats .chart-legends.chart-legends-deposit div:nth-child(2) h5 span {
  background-color: #C1A6B0;
}

.col-stats .chart-legends.chart-legends-deposit div:nth-child(3) h5 span {
  background-color: #E0DCDC;
}

.col-stats .chart-legends.chart-legends-transaction {
  margin-left: 0;
}

.col-stats .chart-legends.chart-legends-transaction div:first-child h5 span {
  background-color: #161618;
}

.col-stats .chart-legends.chart-legends-transaction div:nth-child(2) h5 span {
  background-color: #89455E;
}

.col-stats .chart-legends.chart-legends-transaction div:nth-child(3) h5 span {
  background-color: #C1A6B0;
}

.col-stats .chart-legends.chart-legends-transaction div:nth-child(4) h5 span {
  background-color: #E6DBDF;
}

.col-stats .chart-legends.chart-legends-transaction div:nth-child(5) h5 span {
  background-color: #EFE8EB;
}

.col-stats .chart-legends.chart-legends-transaction div:nth-child(6) h5 span {
  background-color: #989999;
}

.table-cards td:nth-child(1), .table-cards td:nth-child(5) {
  font-weight: 500;
}

.bo-cardsearch {
  position: absolute;
  background: #ffffff;
  -webkit-box-shadow: 0rem 0rem 1.5rem 0.2rem rgba(193, 166, 176, 0.5);
          box-shadow: 0rem 0rem 1.5rem 0.2rem rgba(193, 166, 176, 0.5);
  border-radius: 0.8rem;
  width: 100%;
  padding: 3.7rem 3.2rem;
  z-index: 3;
}

.issueCardSmall p {
  font-weight: 500;
  line-height: 1.96rem;
}

.ul-card-details-popup {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.ul-card-details-popup li label {
  font-weight: 500;
}

.ul-card-details-popup li span {
  font-weight: 600;
  word-break: break-all;
  padding-left: 3rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.ul-card-details-popup li span .icon-hidden {
  font-size: 3rem;
  cursor: pointer;
}

.ul-card-details-popup li span .icon-hidden::before {
  color: #989999;
}

.ul-card-details-popup li.li-send-money span:last-child {
  color: #989999;
  font-size: 1.2rem;
}

.ul-card-details-popup li div span {
  padding-left: 0;
  color: #89455E;
}

.ul-card-details-popup li div b {
  padding-left: 1rem;
  font-weight: 600;
  color: #161618;
}

.ul-card-details-popup.ul-dashboard .td-logo {
  background-color: #E6DBDF;
}

.ul-card-details-popup.ul-issue-card li span {
  padding-left: 0rem;
  color: #161618;
}

.carousel-mycards {
  margin-left: -1rem;
}

.carousel-mycards .carousel-control-next {
  right: -1.2rem;
}

.bo-card-box {
  margin-bottom: 1.2rem;
  border-radius: 1.2rem;
  padding: 1.1rem 1.6rem;
  min-height: calc(100% - 1.2rem);
}

.bo-card-box h4 {
  font-weight: 700;
}

.bo-card-box .bo-card-stats span {
  font-weight: 600;
}

.bo-card-box .bo-card-stats span:first-child {
  font-size: 1.2rem;
}

.bo-card-box .bo-card-stats span:last-child {
  margin-left: auto;
  color: #89455E;
  font-size: 10px;
}

.bo-card-box p {
  color: #989999;
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0;
}

.bo-right-sidebar-wrap {
  padding: 4rem 3.2rem;
}

.bo-help p {
  font-size: 1.2rem;
  margin-top: 1.6rem;
  max-width: 26.5rem;
  width: 100%;
}

.row-authentication p {
  margin-top: -1.6rem;
}

.row-authentication p b {
  font-size: 1.6rem;
}

.row-card-holder-details {
  background-color: #F4F1F2;
  padding: 2.4rem 3.2rem 0.5rem;
  border-radius: 1.2rem;
}

.row-card-holder-details .ul-card-details {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.row-card-holder-details .ul-card-details li {
  display: inline-block;
  margin-right: 6rem;
  vertical-align: top;
}

.row-card-holder-details .ul-card-details li p {
  font-size: 1.2rem;
  color: #989999;
  margin-bottom: 0.3rem;
  font-weight: 600;
}

.row-card-holder-details .ul-card-details li:last-child {
  margin-right: 0;
}

.bo-col-transaction div:first-child {
  position: relative;
  margin-right: 1.7rem;
}

.bo-col-transaction div:first-child span {
  width: 0.8rem;
  height: 0.8rem;
  background-color: #89455E;
  border: 1px solid #ffffff;
  border-radius: 100%;
  position: absolute;
  top: -0.1rem;
  right: 0;
}

.bo-col-transaction div:last-child p {
  color: #989999;
  margin-bottom: 0;
}

.table-details td:nth-child(5) {
  font-weight: 500;
}

.table-clients .td-address {
  width: 20.5rem;
}

.table-clients .td-name {
  width: 15rem;
}

.checkbox-block-ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.checkbox-block-ul.mt--24 {
  margin-top: -2.4rem;
}

.checkbox-block-ul li {
  margin-bottom: 0.6rem;
}

.no-clients-msg {
  padding: 5rem 1rem;
}

.no-clients-msg h3 {
  font-weight: 700;
  margin-bottom: 1.6rem;
  margin-top: 2.2rem;
}

.logo-large {
  max-width: 12rem;
  width: 100%;
  /* height: 12rem; */
  border-radius: 1rem;
  background-color: #EFE8EB;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.logo-large img {
  max-width: 12rem;
  /* height: 12rem; */
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
  border-radius: 1rem;
}

.table-deposit td:nth-child(1), .table-deposit td:nth-child(3) {
  font-weight: 500;
}

.form-group.mt--2 {
  margin-top: -1.5rem;
}

.drop-theme {
  background: #ffffff;
  /* Popup effect */
  -webkit-box-shadow: 0.4rem 0.4rem 3rem rgba(193, 166, 176, 0.5);
          box-shadow: 0.4rem 0.4rem 3rem rgba(193, 166, 176, 0.5);
  border-radius: 0.8rem;
  border: 0;
  width: 100%;
  padding: 1.6rem 1.6rem 0.4rem;
}

.drop-theme .theme-wrap {
  margin-bottom: 1.2rem;
}

.drop-theme .theme-wrap p {
  font-size: 1.2rem;
  margin-bottom: 0rem;
  padding-left: 3rem;
}

.drop-theme .theme-wrap label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.drop-theme .theme-wrap label span {
  max-width: 16%;
  width: 100%;
  height: 24px;
}

.drop-theme .theme-wrap label span:first-child {
  border-radius: 4px 0px 0px 4px;
}

.drop-theme .theme-wrap label span:last-child {
  border-radius: 0px 4px 4px 0px;
}

.drop-theme .theme-wrap.theme1 span:first-child {
  background-color: #161618;
}

.drop-theme .theme-wrap.theme1 span:nth-child(2) {
  background-color: #89455e;
}

.drop-theme .theme-wrap.theme1 span:nth-child(3) {
  background-color: #989999;
}

.drop-theme .theme-wrap.theme1 span:nth-child(4) {
  background-color: #c1a6b0;
}

.drop-theme .theme-wrap.theme1 span:nth-child(5) {
  background-color: #e0dcdc;
}

.drop-theme .theme-wrap.theme1 span:nth-child(6) {
  background-color: #e6dbdf;
}

.drop-theme .theme-wrap.theme2 span:first-child {
  background-color: #0c1446;
}

.drop-theme .theme-wrap.theme2 span:nth-child(2) {
  background-color: #175873;
}

.drop-theme .theme-wrap.theme2 span:nth-child(3) {
  background-color: #2b7c85;
}

.drop-theme .theme-wrap.theme2 span:nth-child(4) {
  background-color: #a9c7bf;
}

.drop-theme .theme-wrap.theme2 span:nth-child(5) {
  background-color: #d0e7e0;
}

.drop-theme .theme-wrap.theme2 span:nth-child(6) {
  background-color: #e6f6f1;
}

.drop-theme .theme-wrap.theme3 span:first-child {
  background-color: #03045e;
}

.drop-theme .theme-wrap.theme3 span:nth-child(2) {
  background-color: #0077b6;
}

.drop-theme .theme-wrap.theme3 span:nth-child(3) {
  background-color: #00b4d8;
}

.drop-theme .theme-wrap.theme3 span:nth-child(4) {
  background-color: #48cae4;
}

.drop-theme .theme-wrap.theme3 span:nth-child(5) {
  background-color: #90e0ef;
}

.drop-theme .theme-wrap.theme3 span:nth-child(6) {
  background-color: #caf0f8;
}

.drop-theme .theme-wrap.theme4 span:first-child {
  background-color: #472d30;
}

.drop-theme .theme-wrap.theme4 span:nth-child(2) {
  background-color: #723d46;
}

.drop-theme .theme-wrap.theme4 span:nth-child(3) {
  background-color: #b06c62;
}

.drop-theme .theme-wrap.theme4 span:nth-child(4) {
  background-color: #c28981;
}

.drop-theme .theme-wrap.theme4 span:nth-child(5) {
  background-color: #c9cba3;
}

.drop-theme .theme-wrap.theme4 span:nth-child(6) {
  background-color: #dfe0cc;
}

.drop-theme .theme-wrap.theme5 span:first-child {
  background-color: #774936;
}

.drop-theme .theme-wrap.theme5 span:nth-child(2) {
  background-color: #b07d62;
}

.drop-theme .theme-wrap.theme5 span:nth-child(3) {
  background-color: #c38e70;
}

.drop-theme .theme-wrap.theme5 span:nth-child(4) {
  background-color: #e6b8a2;
}

.drop-theme .theme-wrap.theme5 span:nth-child(5) {
  background-color: #edc4b3;
}

.drop-theme .theme-wrap.theme5 span:nth-child(6) {
  background-color: #fdded2;
}

.drop-theme .theme-wrap.theme6 span:first-child {
  background-color: #222831;
}

.drop-theme .theme-wrap.theme6 span:nth-child(2) {
  background-color: #393E46;
}

.drop-theme .theme-wrap.theme6 span:nth-child(3) {
  background-color: #00ADB5;
}

.drop-theme .theme-wrap.theme6 span:nth-child(4) {
  background-color: #A8B2C1;
}

.drop-theme .theme-wrap.theme6 span:nth-child(5) {
  background-color: #C9D4E4;
}

.drop-theme .theme-wrap.theme6 span:nth-child(6) {
  background-color: #EEEEEE;
}

.drop-theme .theme-wrap.theme7 span:first-child {
  background-color: #145da0;
}

.drop-theme .theme-wrap.theme7 span:nth-child(2) {
  background-color: #0c2d48;
}

.drop-theme .theme-wrap.theme7 span:nth-child(3) {
  background-color: #2e8bc0;
}

.drop-theme .theme-wrap.theme7 span:nth-child(4) {
  background-color: #88c9df;
}

.drop-theme .theme-wrap.theme7 span:nth-child(5) {
  background-color: #b1d4e0;
}

.drop-theme .theme-wrap.theme7 span:nth-child(6) {
  background-color: #d8eff6;
}

.drop-theme .theme-wrap.theme8 span:first-child {
  background-color: #1E212D;
}

.drop-theme .theme-wrap.theme8 span:nth-child(2) {
  background-color: #B68973;
}

.drop-theme .theme-wrap.theme8 span:nth-child(3) {
  background-color: #C09F8F;
}

.drop-theme .theme-wrap.theme8 span:nth-child(4) {
  background-color: #EABF9F;
}

.drop-theme .theme-wrap.theme8 span:nth-child(5) {
  background-color: #F8D7BF;
}

.drop-theme .theme-wrap.theme8 span:nth-child(6) {
  background-color: #FAF3E0;
}

.drop-theme .theme-wrap.theme9 span:first-child {
  background-color: #000000;
}

.drop-theme .theme-wrap.theme9 span:nth-child(2) {
  background-color: #06225F;
}

.drop-theme .theme-wrap.theme9 span:nth-child(3) {
  background-color: #EA7C3A;
}

.drop-theme .theme-wrap.theme9 span:nth-child(4) {
  background-color: #FC983F;
}

.drop-theme .theme-wrap.theme9 span:nth-child(5) {
  background-color: #767171;
}

.drop-theme .theme-wrap.theme9 span:nth-child(6) {
  background-color: #C2C2C2;
}

.send-money-table1 {
  padding-right: 2.5rem;
}

.send-money-table2 {
  padding-left: 2.5rem;
}

.a-star {
  font-weight: 600;
}

.a-star i {
  position: relative;
  top: 0.4rem;
}

.ul-settings {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.ul-settings li {
  margin-bottom: 1.6rem;
  line-height: 2rem;
}

.ul-settings li div {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ul-settings li label {
  color: #989999;
  padding-right: 3.5rem;
}

.ul-settings li span {
  font-weight: 500;
  word-break: break-all;
}

.ul-settings.settings-role li label {
  padding-right: 4.5rem;
}

.ul-settings.settings-role li:last-child label {
  padding-right: 1.2rem;
}

.ul-settings-sub {
  list-style-type: disc;
}

.ul-settings-sub li {
  margin-bottom: 0.6rem;
}

.favorites div:first-child p {
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 0;
  color: #C1A6B0;
  font-weight: 600;
}

.favorites div:last-child {
  text-align: right;
}

.favorites div:last-child img {
  cursor: pointer;
}

.action__menu {
  min-width: 25rem;
  background-color: #F4F1F2;
  -webkit-box-shadow: 0rem 0rem 2.3rem rgba(22, 22, 24, 0.08);
          box-shadow: 0rem 0rem 2.3rem rgba(22, 22, 24, 0.08);
  border-radius: 1.2rem;
  border: none;
  padding: 2.4rem;
  font-size: 1.2rem;
  color: #000000;
  top: -1.4rem !important;
}

.action__menu p {
  margin-bottom: 0;
}

.id-doc img {
  max-width: 365px;
  width: auto;
  border-radius: 6px;
}

.add-paper img {
  max-width: 382px;
  width: auto;
  border-radius: 6px;
}

.react-tel-input {
  font-family: "Inter", Arial !important;
  font-size: 1.4rem !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: auto !important;
  line-height: 1.5 !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: 0.1rem solid #C1A6B0 !important;
  border-right: none !important;
  border-radius: 0.8rem 0 0 0.8rem !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
  border-radius: 0.8rem 0 0 0.8rem !important;
}

.react-tel-input .flag-dropdown .selected-flag {
  width: 4.7rem;
  padding-left: 1.25rem;
}

.react-tel-input .flag-dropdown .selected-flag:hover, .react-tel-input .flag-dropdown .selected-flag:focus {
  background-color: transparent !important;
  border-radius: 0.8rem 0 0 0.8rem !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  padding: 0 !important;
  font-family: "Inter", Arial !important;
  color: #161618 !important;
  border: none !important;
  border-radius: 0 !important;
  font-size: 1.4rem !important;
}

.ReactFlagsSelect-module_selectValue__152eS {
  padding: 0 !important;
  margin: 0 !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7:after {
  border: 0 !important;
  content: url("../../assets/images/darrow.svg") !important;
  width: 1.9rem !important;
  height: auto !important;
  margin-left: 0 !important;
}

.react-datepicker-wrapper {
  display: block !important;
  width: 100%;
}

.react-datepicker-popper {
  z-index: 5 !important;
}

.react-datepicker {
  font-family: "Inter", Arial !important;
  color: #161618 !important;
  font-size: 1.4rem !important;
  background: #F4F1F2 !important;
  -webkit-box-shadow: 0rem 0rem 2.3rem rgba(22, 22, 24, 0.08) !important;
          box-shadow: 0rem 0rem 2.3rem rgba(22, 22, 24, 0.08) !important;
  border-radius: 1.2rem !important;
  border: none !important;
  min-width: 280px;
  padding: 0 1rem 1rem 0.3rem;
}

.react-datepicker-popper {
  width: 100%;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: 0 !important;
}

.react-datepicker__current-month {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  color: #89455E !important;
  padding: 1rem;
}

.react-datepicker__day-names {
  border-bottom: 1px solid #e7e7e7 !important;
}

.react-datepicker__day-name {
  color: #161618 !important;
  font-size: 1.2rem !important;
  padding: 0.8rem !important;
  width: 3.5rem !important;
  text-transform: uppercase !important;
}

.react-datepicker__navigation--previous {
  left: 1.5rem !important;
  border-right-color: #89455E !important;
  top: 2.3rem !important;
}

.react-datepicker__navigation--next {
  border-left-color: #89455E !important;
  right: 1.5rem !important;
  top: 2.3rem !important;
}

.react-datepicker__navigation--years-upcoming {
  border-bottom-color: #89455E !important;
  top: -0.2rem !important;
}

.react-datepicker__navigation--years-previous {
  border-top-color: #89455E !important;
  top: 0.2rem !important;
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  border-top-color: #89455E !important;
  border-width: 0.42rem !important;
}

.react-datepicker__day {
  font-size: 1.4rem !important;
  padding: 0.8rem !important;
  width: 3.5rem !important;
  height: 3.5rem !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #89455E !important;
  color: #ffffff !important;
  border-radius: 100% !important;
}

.react-datepicker__month-container {
  width: 100%;
}

.td-address {
  width: 200px;
}

@media only screen and (max-width: 767px) {
  html body .onbo-sidebar .onbo-inn {
    height: auto;
    background-image: none;
    padding: 2rem 2rem 1rem;
    margin: 0;
  }
  html body .onbo-content .bo-on-screens {
    padding-top: 0rem;
  }
  html body .onbo-content {
    border-radius: 3.2rem 3.2rem 0rem 0rem;
    padding: 4rem 2rem;
  }
  html body .onbo-content.onbo-content-mbl {
    border-radius: 0;
  }
  html body .onbo-content-top {
    margin-top: 7rem;
  }
  html body .table-wrap {
    overflow-x: auto;
    margin-top: 1rem;
  }
  html body .table-wrap .table-cards,
  html body .table-wrap .table-details,
  html body .table-wrap .table-clients,
  html body .table-wrap .table-deposit {
    width: 115rem;
  }
  html body .table-wrap .table-cards2 {
    width: 110rem;
  }
  html body .table-wrap .table-cards2 {
    width: 110rem;
  }
  html body .smallPopup .modal-body {
    padding: 3rem 2rem 3.2rem;
  }
  html body .modal {
    padding-right: 0 !important;
    padding-top: 1rem;
  }
  html body .modal .modal-dialog-centered {
    min-height: calc(100% - 0rem);
    margin: 0 auto;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  html body .modal .modal-dialog-centered .modal-content {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  html body .bo-tabs-mbl {
    position: relative;
    display: block;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  html body .bo-tabs-mbl .nav-tabs {
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    border-bottom: 0;
    margin-bottom: 1rem;
  }
  html body .bo-tabs-mbl .nav-tabs .nav-item {
    display: inline-block;
    margin-left: -4px;
    margin-bottom: 0;
    width: auto;
    margin-right: 0rem;
  }
  html body .bo-tabs-mbl .nav-tabs .nav-item .nav-link {
    border-bottom: 0.2rem solid #E6DBDF;
    padding-right: 2rem;
  }
  html body .bo-tabs-mbl .nav-tabs .nav-item .nav-link.active {
    border-bottom: 0.2rem solid #89455E;
  }
  html body .nav-tabs .nav-item {
    margin-right: 2rem;
  }
  html body .nav-tabs.nav-tabs-profile .nav-item {
    margin-right: 0;
  }
  html body .nav-tabs.nav-tabs-profile .nav-item .nav-link {
    padding: 0rem 3rem 2.5rem 3rem;
  }
  html body .checkbox-block-ul.mt--24 {
    margin-top: -1rem;
  }
  html body .row-head-clients .form-group {
    margin-bottom: 0;
  }
  html body .col-stats .chart-legends.chart-legends-deposit, html body .col-stats .chart-legends.chart-legends-transaction {
    margin-left: 2rem;
  }
  html body .btn,
  html body input[type="submit"] {
    min-width: 13rem;
  }
  html body form .form-group.submit-field.form-search button {
    min-width: auto;
  }
  html body .bo-cardsearch .form-search.mr-20 {
    margin-right: 0;
  }
  html body .bo-cardsearch .form-search.submit-field {
    margin-top: 2rem;
  }
  html body .carousel-control-next,
  html body .carousel-control-prev {
    display: none;
  }
  html body .mt-70 {
    margin-top: 4rem;
  }
  html body .particles-mbl {
    display: block;
  }
  html body .form-group.mb-3 {
    margin-bottom: 2.8rem !important;
  }
  html body .form-group.mt--2 {
    margin-top: 0;
  }
  html body .onbo-content .alert-mbl {
    background-color: #E6DBDF;
    top: -11.6rem;
  }
  html body .onbo-content .alert-mbl-common {
    top: 1.5rem;
  }
  html body .register-top-mbl {
    margin-top: 10rem;
  }
  html body .register-top-mbl h3 {
    font-weight: 500;
    font-size: 1.2rem;
  }
  html body .chart-wrap {
    margin: 0 auto 15px;
  }
  html body .input-hide {
    display: none;
  }
  html body .w-100-mbl {
    width: 100%;
  }
  html body .onbo-side-mbl {
    display: none;
  }
  html body .onbo-content-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
  html body .d-xs-none {
    display: none;
  }
  html body .col-stats-mbl {
    min-height: calc(100% - 1rem);
  }
  html body .bo-main-content .bo-main-content-sub {
    padding: 2rem 0;
  }
  html body .header-main-outer {
    padding-top: 3.5rem;
  }
}
.error_meesage {
  font-size: 1.2rem;
  margin-top: 0.6rem;
  display: block;
  font-weight: 400;
  color: #89455E;
}
@media only screen and (max-width: 575px) {
  html body form .form-group.form-search {
    display: block;
  }
  html body form .form-group.form-search label {
    width: 100%;
  }
  html body form .form-group.form-search .form-controls {
    width: 100%;
  }
  html body .id-doc img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  html body .add-paper img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .nav-tabs.nav-tabs-allclients .nav-item {
    margin-right: 1rem;
  }
  .nav-tabs.nav-tabs-allclients .nav-item .nav-link {
    font-size: 1rem;
    padding: 0rem 0rem 2.5rem 0rem;
  }
  .btns-four .btn {
    min-width: 8rem;
  }
  .btns-four .mr-19 {
    margin-right: 1.3rem;
  }
  .row-card-holder-details {
    padding: 2.4rem 1.5rem 0.5rem;
  }
  .row-card-holder-details .ul-card-details li {
    margin-right: 0rem;
  }
  .btn-edit {
    min-width: auto !important;
    width: 100%;
  }
  form .form-controls.form-card-state select {
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 767px) {
  .particles-mbl {
    display: none;
  }
  .mt-sm-100 {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 375px) {
  html body .chart-wrap img {
    max-width: 90%;
  }
  html body .col-stats .chart-legends {
    margin-left: 1rem;
  }
  html body .nav-tabs {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  html body .nav-tabs .nav-item {
    margin-right: 0rem !important;
  }
  html body .btn-outline.btn-outline-large {
    padding: 0.9rem 1rem !important;
  }
  html body .logo-large {
    max-width: 9rem;
    width: 100%;
    height: 9rem;
  }
  .react-datepicker {
    min-width: 100%;
  }
  .react-datepicker__day {
    padding: 0.5rem !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
  .react-datepicker__day-name {
    padding: 0.5rem !important;
    width: 2.5rem !important;
  }
  form .form-group.authentications .form-controls input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  form .form-group.submit-field.form-search button {
    width: 100%;
  }
  form .form-group.submit-field.form-search button:last-child {
    margin-top: 1rem;
  }

}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  html body .nav-tabs {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  html body .nav-tabs .nav-item {
    margin-right: 0;
  }
  html body .nav-tabs.nav-tabs-allclients {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .mt-sm-100 {
    margin-top: 100px !important;
  }
  .res-custom-btn {
    font-size: 8px !important;
    font-weight: 600 !important; 
    min-width: fit-content !important;
  }
}

@media only screen and (max-width: 991px) {
  html body .bo-app-main {
    padding: 0;
  }
  html body .bo-app-main-double-sidebar {
    padding: 0;
  }
  html body .bo-main-content {
    padding: 2rem 1.8rem 2.4rem;
    border-radius: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  html body .bo-right-sidebar-wrap {
    padding: 2rem 1rem 0rem;
    background-color: #ffffff;
  }
  html body .col-stats.col-stats-action {
    padding: 3.4rem 0 3rem;
  }
  html body .col-stats-chart.mb-40,
  html body .col-stats-card.mb-40 {
    margin-bottom: 1.2rem;
  }
  html body .col-stats.col-stats-card {
    padding-bottom: 6.5rem;
  }
  html body .row-search div:first-child.mb-24 {
    margin-bottom: 0rem;
  }
  html body .row-search div .form-group {
    margin-bottom: 1.2rem;
  }
  html body .carousel-item img {
    margin: auto;
  }
  html body .bo-sidebar-col {
    width: 0;
    display: none;
  }
  html body .bo-sidebar-col .bo-sidebar-outer {
    background-color: #F4F1F2;
    margin: 0;
    max-width: 24.7rem;
    z-index: 8;
    min-height: 100%;
    overflow-y: auto;
  }
  .res-custom-btn {
    font-size: 8px !important;
    font-weight: 600 !important; 
    min-width: fit-content !important;
  }
  html body form.form-dashboard .form-controls
input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
    border: 0.1rem solid #C1A6B0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  html body .onbo-content {
    padding: 12rem 1.5rem 2rem 4rem;
  }
  html body .row-card-holder-details .ul-card-details li {
    margin-right: 2rem;
    max-width: 20rem;
    width: 100%;
  }
  html body .nav-tabs.nav-tabs-profile .nav-item {
    margin-right: 0rem;
  }
  html body .nav-tabs.nav-tabs-profile .nav-item .nav-link {
    padding: 0rem 4rem 2.5rem 1rem;
  }
  html body .nav-tabs.nav-tabs-allclients .nav-item {
    margin-right: 1rem;
  }
  html body .bo-cardsearch .form-search.submit-field {
    margin-top: 2rem;
  }
  html body .carousel-mycards .carousel-control-prev {
    left: -1.2rem;
  }
  html body .table-wrap {
    overflow-x: auto;
    margin-top: 1rem;
  }
  html body .table-wrap .table-cards,
  html body .table-wrap .table-details,
  html body .table-wrap .table-clients,
  html body .table-wrap .table-deposit {
    width: 105rem;
  }
  html body .nav-tabs.nav-tabs-profile .nav-item .nav-link {
    padding: 0rem 1rem 2.5rem 1rem;
  }
  .res-custom-btn {
    font-size: 8px !important;
    font-weight: 600 !important; 
    min-width: fit-content !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  html body .row-search div:first-child.mb-24 {
    margin-bottom: 0rem;
  }
  html body .row-search div .form-group {
    margin-bottom: 1.2rem;
  }
  html body .row-card-holder-details .ul-card-details li {
    margin-right: 2rem;
    max-width: 18rem;
    width: 100%;
  }
  html body .nav-tabs.nav-tabs-profile .nav-item {
    margin-right: 0rem;
  }
  html body .nav-tabs.nav-tabs-profile .nav-item .nav-link {
    padding: 0rem 4rem 2.5rem 1rem;
  }
  html body .bo-cardsearch .form-search.submit-field {
    margin-top: 2rem;
  }
  html body .carousel-item img {
    margin: auto;
  }
  html body .header-main-outer {
    padding: 4rem 3.2rem;
  }
  html body .col-stats-action {
    padding: 3.4rem 0 3rem;
  }
  html body .onbo-content-ipadpro {
    padding: 7rem 3rem 2rem 3rem;
  }
  .res-custom-btn {
    font-size: 8px !important;
    font-weight: 600 !important; 
    min-width: fit-content !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .carousel-item img {
    width: 94%;
  }
  html body .nav-tabs.nav-tabs-profile .nav-item .nav-link {
    padding: 0rem 1rem 2.5rem 1rem;
  }
  html body .bo-table th {
    padding-right: 10px;
  }
  html body .bo-table td {
    padding-right: 10px;
  }
  .res-custom-btn {
    font-size: 14px !important;
    font-weight: 600 !important; 
    min-width: fit-content !important;
  }
}

@media only screen and (max-width: 1199px) {
  html body .header-main-outer {
    display: block;
  }
  html body .bo-main-content {
    border-radius: 0;
  }
  html body .bo-app-main-double-sidebar,
  html body .bo-app-main {
    padding: 0;
  }
  html body .bo-menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 6;
  }
  html body .bo-sidebar-col {
    width: 0;
    display: none;
  }
  html body .bo-sidebar-col .bo-sidebar-outer {
    background-color: #F4F1F2;
    margin: 0;
    max-width: 24.7rem;
    z-index: 8;
    min-height: 100%;
    overflow-y: auto;
  }
  html body .onbo-sidebar .onbo-inn {
    height: 100%;
  }
  .res-custom-btn {
    font-size: 8px !important;
    font-weight: 600 !important; 
    min-width: fit-content !important;
  }
}

@media only screen and (min-width: 1200px) {
  html body .header-main-outer {
    display: none;
  }
  .res-custom-btn {
    font-size: 14px !important;
    font-weight: 600 !important; 
    min-width: fit-content !important;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .col-stats.col-stats-chart {
    padding: 1.5rem 1rem;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
